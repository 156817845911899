.Avatar {
    width: auto;
    height: 256px;
    border-radius: 50%;
    margin-top: 25px;
}

@media only screen and (max-width: 960px) {
    .Avatar {
        width: auto;
        height: 192px;
        border-radius: 50%;
    }
}

#title {
    margin-top: 25px;
    margin-bottom: 25px;
    padding-left: 20px;
    padding-right: 20px;
}
