.SocialMedia-logo {
    width: auto;
    height: 64px;
}

@media only screen and (max-width: 600px) {
    .SocialMedia-logo {
        width: auto;
        height: 32px;
    }
}

img {
    padding-left: 0.75em;
    padding-right: 0.75em;
}