.PrivacyPolicy {
    font-family: 'Ubuntu Mono', monospace;
}

.PrivacyPolicy-body {
    padding-left: 20%;
    padding-right: 20%;
}

@media only screen and (max-width: 960px) {
    .PrivacyPolicy-body {
        padding-left: 10%;
        padding-right: 10%;
    }
}
