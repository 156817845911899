.Home {
    text-align: center;
    font-family: 'Ubuntu Mono', monospace;
}

.Home-body {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.Home-body p {
    padding-left: 20%;
    padding-right: 20%;
    line-height: 125%;
}

@media only screen and (max-width: 960px) {
    p {
        padding-left: 10%;
        padding-right: 10%;
    }
}

@media only screen and (max-width: 600px) {
    p {
        padding-left: 5%;
        padding-right: 5%;
    }
}

.Home-body a {
    color: #61dafb;
}

.refresh-color {
    bottom: 0;
    right: 0;
    font-size: 1em;
    padding-top: 8px;
    padding-bottom: 8px;
    margin-right: 8px;
    word-wrap: break-word;
    text-align: right;
    color: white;
}

@media only screen and (max-width: 600px) {
    .refresh-color {
      font-size: 0.75em;
    }
}  

hr {
    margin-top: 1em;
    width: 70%;
}

.Home-body h2 {
    margin-top: 0.5em;
    margin-bottom: -4px;
}