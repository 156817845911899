.retroGallery {
    text-align: center;
    font-family: 'Tiny5', sans-serif;
    background-color: rgb(20, 20, 20);
}

.retroGallery-body {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
    padding-left: 25%;
    padding-right: 25%;
}

@media only screen and (max-width: 600px) {
    .retroGallery-body {
        padding-left: 5%;
        padding-right: 5%;
    }
}

.grid-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 3 columns, each taking up 1 fraction of the available space */
    gap: 20px; /* Space between grid items */
}

.goBack {
    position: absolute;
    top: 0;
    left: 0;
    margin-left: 50px;
    margin-top: 30px;
    color: white;
    font-size: calc(10px + 1.5vmin);
  }
  